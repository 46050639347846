nav {
  position: fixed;
  width: 100%;
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #0f0f0f9f;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  border-bottom: 1px solid var(--nav-border);
  z-index: 2;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
  padding: 1.5rem 2rem;
}

nav ul li a {
  color: var(--text-secondary);
  transition: var(--transition);
  padding-bottom: .5rem;
  border-bottom: 2px solid transparent;
}

nav ul li a:hover {
  color: var(--text-primary);
  border-bottom: 2px solid var(--text-secondary);
}

#logo a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 2rem;
  color: var(--text-primary);
}

span.tag {
  color: #00B0FF;
  font-weight: bold;
}

#logo span.tag:nth-of-type(2n) {
  padding-left: 1rem;
}

#name {
  font-weight: bold;
  font-size: 2rem;
}

#hamburguer-menu {
  display: none;
}

@media (max-width: 37.5em) {
  nav ul {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: -100vh;
    transition: .2s all;
    left: 0;
    background: var(--dark-primary);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  nav ul li {
    font-size: 2rem;
  }

  nav ul li a {
    padding-bottom: 0.5rem;
    border-bottom: 2px solid transparent;
  }

  #hamburguer-menu {
    display: unset;
    position: relative;
    padding-right: 2rem;
    z-index: 3;
  }
}

@media (max-width: 28.75em) {
  /* span.tag {
    display: none;
  } */
}